import { isTokenExpired } from '../contexts/AuthContext';
import axios from 'axios';

const domain = process.env.REACT_APP_DOMAIN;
const api_url = `https://${domain}/api`;
const admin_url = `https://${domain}/dashboard/api/`;

const api = axios.create({
    baseURL: api_url,
    validateStatus: (status) => {
        return true;
    }
});

const admin_api = axios.create({
    baseURL: admin_url,
    validateStatus: (status) => {
        return true;
    }
});

admin_api.interceptors.request.use(async (config) => {
    const accessToken = localStorage.getItem('accessToken');
    
    if (accessToken && !isTokenExpired(accessToken)) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    } else {
        // Handle the case where the token is expired or not available
        console.warn('Access token is expired or not available');
    }
    return config;
});

admin_api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refresh_token');
            if (refreshToken && !isTokenExpired(refreshToken)) {
                try {
                    const response = await axios.post(`${admin_url}token/refresh/`, {
                        refresh: refreshToken,
                    });
                    localStorage.setItem('access_token', response.data.access);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
                    return admin_api(originalRequest);
                } catch (err) {
                    console.error('Token refresh failed:', err);
                }
            }
        }
        return Promise.reject(error);
    }
);

export {
    api,
    api_url,
    admin_api,
    admin_url,
}